import React, { useState } from "react";
import {
    Box,
    TextField,
    Button,
    Typography,
    Alert,
    CircularProgress, Link
} from "@mui/material";
import Header from "../../Components/Header/Header";
import { useSearchParams, useNavigate } from "react-router-dom";
import ForgotPasswordService from "../../apiServices/services/ForgotPasswordService"
const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const code = searchParams.get("code"); // Get reset code from URL
    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setSuccess(null);

        if (!password || !confirmPassword) {
            setError("Both fields are required.");
            return;
        }

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        setLoading(true);
        try {
            await ForgotPasswordService.resetPassword(code, password, confirmPassword);
            setSuccess("Password reset successfully!");
            setTimeout(() => navigate("/login"), 2000); // Redirect to login
        } catch (err) {
            setError(err.message || "Failed to reset password.");
        }
        setLoading(false);
    };
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                minHeight: "100vh",
                backgroundColor: "#F6F5F8",
                color: "#2F2D3B",
            }}
        >
            <Header />
            <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                minHeight={"85vh"}
            >

                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                        maxWidth: "1440px",
                        width: "100%",
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                            maxWidth: "726px",
                            width: "100%",
                        }}
                    >
                        <Box
                            component="form"
                            display="flex"
                            flexDirection="column"
                            sx={{
                                borderRadius: "10px",
                                padding: "3.5rem",
                                maxWidth: "726px",
                                width: "100%",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "700",
                                    fontSize: "2rem",
                                    textAlign: "center",

                                    marginBottom: "1rem",
                                    color: "#0F0049",
                                }}
                            >
                                Please Enter Your Password To Continue.
                               
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    textAlign: "center",
                                    color: "#555555",
                                    mb: 3,
                                }}
                            >
                                 Enter  password and confirm password to continue.
                            </Typography>
                            {error && (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    {error}
                                </Alert>
                            )}
                            {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
                            <Box sx={{ width: "100%", mb: 2 }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "700",
                                        mb: 2,
                                        fontSize: "1.25rem",

                                        color: "#0F0049",
                                    }}
                                >
                                    Password
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                            borderColor: "#d3d3d3",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#d3d3d3",
                                        },
                                        mb: 2,
                                        backgroundColor: "#FFFFFF",
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: "100%", mb: 2 }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "700",
                                        mb: 2,
                                        fontSize: "1.25rem",
                                        color: "#0F0049",
                                    }}
                                >
                                    Confirm password
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                            borderColor: "#d3d3d3",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#d3d3d3",
                                        },
                                        backgroundColor: "#FFFFFF",
                                    }}
                                />
                            </Box>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                                sx={{
                                    mt: 2,
                                    backgroundColor: "#2069F8",
                                    color: "#FFFFFF",
                                    "&:hover": {
                                        backgroundColor: "#181e54",
                                    },
                                }}
                                onClick={handleSubmit}
                            >
                                {loading ? "Loading..." : "Continue"}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ResetPassword;
