import axios from "axios";
import instance from "../instance";

const ForgotPasswordService = {
  sendPasswordResetEmail: async (email) => {
    try {
      const response = await instance.post("/api/auth/forgot-password", {
        email,
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || { message: "Something went wrong" };
    }
  },

  resetPassword: async (code, password, passwordConfirmation) => {
    try {
      const response = await instance.post("/api/auth/reset-password", {
        code,
        password,
        passwordConfirmation,
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || { message: "Failed to reset password" };
    }
  },
};

export default ForgotPasswordService;
