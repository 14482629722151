import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  Button,
  Checkbox,
  Snackbar,
  Alert,
  Modal,
} from "@mui/material";
import { getCompanyData } from "../../apiServices/services/DocumentsServices";
import { updateFormStatus } from "../../apiServices/services/ProfileServices";
import {
  AddCompanyAddress,
  verifyPaymentStatus,
} from "../../apiServices/services/CompanyServices";

const Summary = ({ onContinue, onBack }) => {
  const [companyDetailsData, setCompanyDetailsData] = useState({});
  const [typeOfShares, setTypeOfShares] = useState([]);
  const [directorsData, setDirectorsData] = useState([]);
  const [shareHoldingsData, setShareHoldingsData] = useState([]);
  const [bankingData, setBankingData] = useState({});
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [openPayment, setOpenPaymentModal] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [proceedWithSubmission, setProceedWithSubmission] = useState(false);

  const userId = localStorage.getItem("userId");
  const options = { day: "2-digit", month: "short", year: "numeric" };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (proceedWithSubmission) {
      handleSubmit();
    }
  }, [proceedWithSubmission]);

  const handleConformationModal = () => {
    setShowModal(false);
    setOpenPaymentModal(true);
  };

  const handlePayment = async () => {
    let paymentPayload = {
      email: localStorage.getItem("useremail"),
      companyName: companyDetailsData.companyName,
      packageDetails: {
        packageName: "London Registered Address Fees",
        price: "89.95",
      },
    };
    let paymentWindow;
    let stripeSessionId;
    await AddCompanyAddress(paymentPayload)
      .then((stripeData) => {
        if (stripeData instanceof Error) {
          throw stripeData; // Explicitly throw the error inside `then()`
        }
        const stripeUrl = stripeData.data?.data?.url;
        stripeSessionId = stripeData.data?.data?.id; // Your Stripe checkout URL

        const width = 500;
        const height = 600;
        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        paymentWindow = window.open(
          stripeUrl,
          "_blank",
          `width=${width},height=${height},top=${top},left=${left}`
        );
      })
      .catch((e) => {
        setOpenSnackBar(true);
        setPaymentStatus(false);
        setSnackbarMessage(
          e.response?.data?.error?.message || "Payment failed!"
        );
      });

    // Monitor when the popup closes
    const checkPopupClosed = setInterval(async () => {
      if (paymentWindow && paymentWindow.closed) {
        clearInterval(checkPopupClosed);
        // Check payment status after closing
        await verifyPaymentStatus(stripeSessionId)
          .then((res) => {
            if (res.success) {
              setPaymentStatus(true);
              setOpenSnackBar(true);
              setSnackbarMessage("Payment successful!");
              setCompanyDetailsData((prevState) => ({
                ...prevState,
                manualAddress: "no",
              }));
            } else {
              setOpenSnackBar(true);
              setPaymentStatus(false);
              setSnackbarMessage("Payment failed!");
            }
          })
          .catch((e) => {
            setOpenSnackBar(true);
            setPaymentStatus(false);
            setSnackbarMessage("Payment failed!");
          });
      }
    }, 1000);

    setOpenPaymentModal(false); // Close the modal after opening checkout
  };

  const handleSubmit = () => {
    if (companyDetailsData?.manualAddress === "yes" && !proceedWithSubmission) {
      setShowModal(true);
      return;
    }
    if (companyDetailsData?.manualAddress === "no" && !paymentStatus) {
      setOpenPaymentModal(true);
      return;
    }

    const userId = localStorage.getItem("userId");
    updateFormStatus(userId, {
      isFormCompleted: true,
    }).then((res) => {
      if (res.status === 200) {
        onContinue();
        localStorage.setItem("activeForm", "isFormCompleted");
        window.dispatchEvent(new Event("storage"));
      } else {
      }
    });
    onContinue();
  };

  useEffect(() => {
    getCompanyData(userId).then((response) => {
      if (response) {
        setPaymentStatus(response?.companyDetails?.useRegisteredAddress);
        setCompanyDetailsData(response?.companyDetails);
        setDirectorsData(response.directors?.shareHolders);
        setBankingData(response?.banking);
        setTypeOfShares(response.shares?.shares);
        setShareHoldingsData(response?.shareholdings?.shareHolders);
      }
    });
  }, [userId]);

  return (
    <Box
      sx={{
        width: "100%",
        p: 4,
        borderRadius: 2,
        boxShadow: 3,
        bgcolor: "background.paper",
        margin: "0 auto",
        paddingLeft: "3rem",
      }}
    >
      <Typography
        sx={{
          color: "1C2434",
          fontWeight: "bold",
          fontSize: "1.4rem",
          marginBottom: "1rem",
        }}
      >
        Summary
      </Typography>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "1.04rem",
          width: "100%",
          maxWidth: "800px",
        }}
        paragraph
      >
        Please check all your details carefully before submitting.
      </Typography>

      <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div>

      <Box mt={3}>
        <Typography sx={{ fontWeight: "600", fontSize: "1.1rem" }} mb={2}>
          Companies Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>Company name</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {companyDetailsData?.companyName} {companyDetailsData.suffix}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              Registered address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {companyDetailsData?.manualAddress === "no" &&
                "Use Central London Regsitered Address"}
              {companyDetailsData?.manualAddress === "yes" && (
                <Typography sx={{ fontWeight: "500" }}>
                  {companyDetailsData?.officeAddress}
                  {","}
                  <br />
                  {companyDetailsData?.addressLine1} {", "}
                  {companyDetailsData?.addressLine2}
                  <br />
                  {companyDetailsData?.town}
                  <br />
                  {companyDetailsData?.country} {", "}
                  {companyDetailsData?.postcode}
                  <br />
                </Typography>
              )}{" "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              Registered email address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {companyDetailsData?.email}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>SIC Codes</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "500" }}>
              {companyDetailsData?.additionalTrade?.map((value, index) => (
                <React.Fragment key={index}>
                  {value}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div>

      <Box mt={4}>
        {directorsData?.map((shareHolder, index) => (
          <>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "1rem",
                marginBottom: "1rem",
                marginTop: "2rem",
              }}
              gutterBottom
            >
              Director {index + 1}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  Name
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  {shareHolder.firstName}{" "}
                  {shareHolder.middleName ? shareHolder.middleName : ""}{" "}
                  {shareHolder.surName ? shareHolder.surName : ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  Email Address
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.email}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>Phone Number</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder?.mobileNumber}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>Home Address</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder?.addressLine1} , {shareHolder?.addressLine2}
                  <br />
                  {shareHolder?.country} <br /> {shareHolder?.postCode}
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  Date of birth
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {new Date(shareHolder.dateOfBirth)
                    .toLocaleDateString("en-GB", options)
                    .replace(",", "")}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "1rem",
                marginBottom: "1rem",
                marginTop: "1.5rem",
              }}
            >
              Companies House Identification Items
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  {shareHolder.firstIdentificationType}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.firstIdentificationAnswer}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.seconddentificationType}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.secondIdentificationAnswer}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.thirdIdentificationType}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.thirdIdentificationAnswer}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>Position</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.position}
                </Typography>
              </Grid>
            </Grid>
          </>
        ))}
      </Box>

      <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div>

      <Box mt={4}>
        <Box>
          <Typography
            sx={{ fontWeight: "600", fontSize: "1.1rem", marginBottom: "1rem" }}
            gutterBottom
          >
            Type Of Shares
          </Typography>
          <Grid container spacing={2}>
            {typeOfShares?.map((shares, index) => (
              <>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "500" }}>
                    Share Class {index + 1}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "500" }}>{shares}</Typography>
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      </Box>

      <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div>

      <Box mt={4}>
        <Typography
          sx={{ fontWeight: "600", fontSize: "1.1rem", marginBottom: "1rem" }}
          gutterBottom
        >
          Shareholding
        </Typography>
        {shareHoldingsData?.map((shareHolder, index) => (
          <>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "1rem",
                marginBottom: "1rem",
                marginTop: "1.5rem",
              }}
              gutterBottom
            >
              Director {index + 1}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>Name</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  {shareHolder.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>Share Class</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.typeOfShare}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  No. of Shares
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHolder.noOfShares}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  Person of Significant Control
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: "500" }}>
                  {shareHoldingsData.length === 1 ? "Yes" : "No"}
                </Typography>
              </Grid>
            </Grid>
          </>
        ))}
      </Box>

      <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div>

      <Box mt={4}>
        <Box>
          <Typography
            sx={{ fontWeight: "600", fontSize: "1.1rem", marginBottom: "1rem" }}
            gutterBottom
          >
            Banking
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500" }}>
                Banking Provider
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "500" }}>
                {/* {bankingData?.isisBarclays && "Barclays"}{" "} */}
                {bankingData?.isisBarclays && "Revolut"}{" "}
                {bankingData?.isMonzo && "Monzo"}
                {!bankingData?.isisBarclays &&
                  !bankingData?.isMonzo &&
                  "None chosen"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <div className=" w-[900px] h-[1px] bg-black mb-8 mt-8"></div>

        <Box
          sx={{
            marginBottom: "1rem",
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            maxWidth: "900px",
          }}
        >
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            sx={{
              padding: "0.25rem",
            }}
          />
          <Typography
            sx={{
              marginLeft: "0.5rem",
              fontSize: "1rem",
              fontWeight: "500",
            }}
          >
            I understand that all of the information provided is accurate, and
            therefore, I instruct the Rise Formations team to submit my details
            to Companies House to be processed. Once submitted, only if you have
            paid for a review service will you be eligible to change any
            information.
          </Typography>
        </Box>
      </Box>

      <Box mt={4} display="flex" className="px-3">
        <Button
          sx={{
            marginTop: "1rem",
            textTransform: "none",
            backgroundColor: "#ffffff",
            color: "#cfcfcf",
            border: "1px solid #cfcfcf",
            borderRadius: "1rem",
            padding: "0.4rem 1.5rem",
            fontWeight: 600,
            "&:hover": {
              color: "#2069F8",
              border: "1px solid #2069F8",
            },
          }}
          onClick={onBack}
        >
          Previous
        </Button>
        <Button
          disabled={!isChecked}
          sx={{
            marginTop: "1rem",
            marginLeft: "0.5rem",
            borderRadius: "1rem",
            border: isChecked ? "1px solid" : "1px solid #cfcfcf",
            backgroundColor: isChecked ? "#2069F8" : "#ffffff",
            color: "#ffffff",
            fontWeight: 700,
            padding: "0.5rem 1.5rem",
            textTransform: "none",
            width: "max-content",
            "&:hover": {
              backgroundColor: "#2069F8",
            },
          }}
          onClick={() => handleSubmit()}
        >
          Sumbit
        </Button>
      </Box>
      {/* Confirmation Modal */}
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            textAlign: "center",
            outline: "none",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "700", fontSize: "2rem", textAlign: "center" }}
          >
            Hold On a Moment !
          </Typography>
          <Typography
            sx={{
              mt: 1,
              fontSize: "1.1rem",
              textAlign: "justify",
              maxWidth: "90%", // Ensures consistent width
              margin: "0 auto", // Centers text
              lineHeight: "1.6", // Improves readability
            }}
          >
            We have noticed you haven’t chosen to use our Registered Address
            service. Over 82% of our clients prefer to use our Central London
            Registered Address both for privacy & professional reasons.
            {/* <br /> */}
            Once your company has been incorporated, your registered address
            will be available publicly.
            {/* <br /> */}
            If you would like to change to our registered address, please click{" "}
            <strong>confirm</strong>.
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <Button
              variant="contained"
              sx={{
                mr: 2,
                backgroundColor: "#FF5733",
                color: "#fff",
                borderRadius: "8px",
              }}
              onClick={() => {
                setShowModal(false);
                setProceedWithSubmission(true);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#2069F8",
                color: "#fff",
                borderRadius: "8px",
              }}
              onClick={handleConformationModal}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openPayment} onClose={() => setOpenPaymentModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            textAlign: "center",
            outline: "none",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "700",
              fontSize: "2rem",
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.5rem",
            }}
          >
            Proceed To Payment
          </Typography>

          <>
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                color: "#555555",
                fontSize: "1.2rem",
              }}
            >
              You have chosen our London registered company address. Click the
              button to complete your additional payment of £89.95 + VAT.
            </Typography>
            <Button
              onClick={handlePayment}
              variant="contained"
              sx={{ mt: 3, backgroundColor: "#2069F8" }}
            >
              Pay Now
            </Button>
          </>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => {
          setOpenSnackBar(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackBar(false);
          }}
          severity={paymentStatus ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Summary;
