import instance, { loginInstance } from "../instance";

export const companyDetails = async (amlData) => {
  const data = { data: amlData };
  try {
    const response = await instance.post(
      "/api/company-details",
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const editCompanyDetailsApi = async (id, amlData) => {
  const data = { data: amlData };
  try {
    const response = await instance.put(
      `/api/company-details/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getCompanyDetailsApi = async (id) => {
  try {
    const response = await instance.get(`/api/company-details`);
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getcountries = async () => {
  try {
    const response = await loginInstance.get(
      "https://restcountries.com/v3.1/all"
    );
    return response.data;
  } catch {}
};

export const RegisteredDetailsApi = async (registerData) => {
  const data = { data: registerData };
  try {
    const response = await instance.post(
      "/api/addresses",
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const editRegisteredDetailsApi = async (id, registerData) => {
  const data = { data: registerData };
  try {
    const response = await instance.put(
      `/api/addresses/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getRegisteredDetailsApi = async (id) => {
  try {
    const response = await instance.get(
      `/api/addresses?filters[userId][$eq]=${id}`
    );
    return response?.data?.data[0] || [];
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const ShareDetailsApi = async (shareData) => {
  const data = { data: shareData };
  try {
    const response = await instance.post(
      "/api/share-details",
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const editShareDetailsApi = async (id, shareData) => {
  const data = { data: shareData };
  try {
    const response = await instance.put(
      `/api/share-details/${id}`,
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const getShareDetailsApi = async (id) => {
  try {
    const response = await instance.get(
      `/api/share-details?filters[userId][$eq]=${id}`
    );
    return response?.data?.data[0] || [];
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const PostCodeApi = async (searchPostCode) => {
  const data = {
    pincode: searchPostCode,
  };
  try {
    const response = await loginInstance.post("/api/searched-locations", data);
    return response?.data?.data;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

export const AddCompanyAddress = async (paymentPayload) => {
  const data = { data: paymentPayload };
  try {
    const response = await instance.post(
      "/api/company-detail/use-company-address",
      JSON.stringify(data)
    );
    return response;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return error;
  }
};

// Function to check payment status
export const verifyPaymentStatus = async (stripeSessionId) => {
  try {
    const response = await instance.get(
      `/api/company-detail/check-payment-status?sessionId=${stripeSessionId}`
    ); // Call backend to verify status
    return response?.data;
  } catch (error) {
    console.error("Error verifying payment:", error);
    return error;
  }
};
