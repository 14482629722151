import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Paper,
  Button,
  RadioGroup,
  Radio,
  TextField,
  Autocomplete,
  Modal,
} from "@mui/material";
import {
  getCompanyDetailsApi,
  companyDetails,
  editCompanyDetailsApi,
} from "../../apiServices/services/CompanyServices";
import { updateFormStatus } from "../../apiServices/services/ProfileServices";
import { searchCompany } from "../../apiServices/services/CheckoutServices";
import CloseIcon from "@mui/icons-material/Close";
import { SICCodes } from "../../Components/constants";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation } from "react-router-dom";
import { countries } from "../../constants/countries";

const CompanyDetail = ({ onContinue }) => {
  const [userId, setUserId] = useState(0);
  const [counntries, setCountries] = useState(countries);
  const [nationality, setNationality] = useState(countries);
  const location = useLocation();
  const riseFormationAddressItem = location.state?.riseFormationAddressItem;
  const [formData, setFormData] = useState({
    companyName: localStorage.getItem("companyName") ?? "",
    suffix: "",
    email: "",
    manualAddress: "no",
    officeAddress: "",
    postcode: "",
    addressLine1: "",
    addressLine2: "",
    town: "",
    region: "",
    country: "",
    useRegisteredAddress: false,
    additionalTrade: [],
    nationality: "",
    isFormCompleted: false,
    userId: localStorage.getItem("userId"),
  });

  const [isCompanyNameAvailable, setIsCompanyNameAvailable] = useState(false);
  const [isNameSearchedSuccessfully, setIsNameSearchedSuccessfully] =
    useState(false);
  const [manualAddress, setManualAddress] = useState("");
  const [open, setOpen] = useState(false);
  const [postAddress, setPostAddress] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [subRegion, setSubRegion] = useState("");
  const [postCode, setPostCode] = useState("");
  const newTradeRef = useRef(null);

  const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
    "&.MuiCheckbox-root": {
      padding: 0,
      marginRight: "6px",
    },
    "& .MuiSvgIcon-root": {
      borderRadius: "50%", // Make the icon circular
    },
    "&.Mui-checked": {
      color: "#ffffff", // White color for the checkmark
    },
    "&.Mui-checked .MuiSvgIcon-root": {
      backgroundColor: "#4caf50", // Green background when checked
      borderRadius: "50%",
      border: "2px solid #4caf50", // Add a green border to maintain the circular shape
    },
    "&.Mui-disabled .MuiSvgIcon-root": {
      backgroundColor: "#4caf50", // Green background even when disabled
      borderRadius: "50%",
      color: "#ffffff", // White checkmark when disabled
    },
  }));

  const RedCheckbox = styled(Checkbox)(({ theme }) => ({
    "&.MuiCheckbox-root": {
      padding: 0,
      marginRight: "6px",
    },
    "& .MuiSvgIcon-root": {
      borderRadius: "50%", // Circular shape
    },
    "&.Mui-checked": {
      color: "#ffffff", // White color for the cross
    },
    "&.Mui-checked .MuiSvgIcon-root": {
      backgroundColor: "#f44336", // Red background when checked
      borderRadius: "50%",
      border: "2px solid #f44336", // Red border for the circular shape
    },
    "&.Mui-disabled .MuiSvgIcon-root": {
      backgroundColor: "#f44336", // Red background when disabled
      borderRadius: "50%",
      color: "#ffffff", // White cross when disabled
    },
  }));

  useEffect(() => {
    const getcountriess = async () => {
      // Check if counntries is an array or object
      const allCountries = Array.isArray(counntries)
        ? counntries
        : Object.values(counntries || {});

      const countryNames = allCountries
        ?.map((country) => country?.name?.common)
        .filter(Boolean);

      const sortedCountryNames = [...countryNames].sort((a, b) =>
        a.localeCompare(b)
      );

      const ukIndex = sortedCountryNames.indexOf("United Kingdom");
      if (ukIndex !== -1) {
        sortedCountryNames.splice(ukIndex, 1);
        sortedCountryNames.unshift("United Kingdom");
      }

      setCountries(sortedCountryNames);
    };

    getcountriess();

    const getNationality = async () => {
      // Check if nationality is an array or object
      const allCountries = Array.isArray(counntries)
        ? counntries
        : Object.values(counntries || {});

      const nationalityNames = allCountries
        ?.map((country) => country?.demonyms?.eng?.f)
        .filter(Boolean);

      const sortedNationalityNames = [...nationalityNames].sort((a, b) =>
        a.localeCompare(b)
      );

      const ukIndex = sortedNationalityNames.indexOf("British");
      if (ukIndex !== -1) {
        sortedNationalityNames.splice(ukIndex, 1);
        sortedNationalityNames.unshift("British");
      }
      setNationality(sortedNationalityNames);
    };

    getNationality();

    getCompanyDetailsApi(formData.userId).then((response) => {
      if (response?.attributes?.results[0]) {
        const result = response.attributes.results[0];
        setUserId(result.id);
        setFormData((prevState) => ({
          ...prevState,
          companyName: result.companyName,
          suffix: result.suffix,
          email: result.email,
          manualAddress: result.manualAddress,
          officeAddress: result.officeAddress,
          postcode: result.postcode,
          addressLine1: result.addressLine1,
          addressLine2: result.addressLine2,
          town: result.town,
          region: result.region,
          additionalTrade: result.additionalTrade,
          useRegisteredAddress: result.useRegisteredAddress,
          isDirector: result.isDirector,
          country: result.country,
          nationality: result.nationality,
          isFormCompleted: result.isFormCompleted,
        }));
      }
    });
    if (
      JSON.parse(localStorage.getItem("addedFeatures")).includes(
        "Central London Registered Address"
      )
    ) {
      setFormData((prevState) => ({
        ...prevState,
        useRegisteredAddress: true,
      }));
    }
  }, []);

  const handleChangeTrade = (index, value) => {
    setFormData((prevState) => {
      const updatedTrade = prevState.additionalTrade
        ? [...prevState.additionalTrade]
        : [];
      updatedTrade[index] = value;
      return { ...prevState, additionalTrade: updatedTrade };
    });
  };

  const handleRemoveTrade = () => {
    setFormData((prev) => ({
      ...prev,
      additionalTrade: prev.additionalTrade.slice(0, -1),
    }));
  };

  const handleAddTrade = () => {
    const newTrade = "";
    setFormData((prevFormData) => {
      const updatedTrade = [...prevFormData.additionalTrade, newTrade];
      return { ...prevFormData, additionalTrade: updatedTrade };
    });
    setTimeout(() => {
      if (newTradeRef.current) {
        newTradeRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const onRoleChange = (isDirector) => {
    setFormData((prevState) => ({
      ...prevState,
      isDirector: isDirector,
    }));
  };
  const handleChange = (e, name) => {
    const { value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChangeCompanyName = (e, name) => {
    const { value } = e.target;
    setIsNameSearchedSuccessfully(false);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const {
      companyName,
      suffix,
      email,
      manualAddress,
      addressLine1,
      addressLine2,
      town,
      country,
      nationality,
      postcode,
    } = formData;

    if (!companyName || !suffix || !email || !nationality) {
      alert(`Please fill all the details for Company`);
      return;
    }

    if (
      manualAddress === "yes" &&
      (!addressLine1 || !addressLine2 || !town || !postcode || !country)
    ) {
      alert(`Please fill all the address details`);
      return;
    }

    if (!formData.additionalTrade[0]) {
      alert(`Please fill Bussiness activities`);
      return;
    }
    if (!formData.isFormCompleted) {
      companyDetails({ ...formData, isFormCompleted: true }).then((res) => {
        if (res.status === 200) {
          updateFormStatus(formData.userId, {
            isCompanyDetailsCompleted: true,
          }).then((res) => {
            if (res.status === 200) {
              onContinue();
              localStorage.setItem("activeForm", "isCompanyDetailsCompleted");
              localStorage.removeItem("companyName");
            }
          });
        }
      });
    } else {
      editCompanyDetailsApi(userId, { ...formData }).then((res) => {
        if (res.status === 200) {
          onContinue();
          localStorage.setItem("activeForm", "isCompanyDetailsCompleted");
          localStorage.removeItem("companyName");
        }
      });
    }
  };

  const handleSearch = () => {
    if (!formData.companyName) {
      return;
    }
    searchCompany({ companyName: formData.companyName })
      .then((res) => {
        if (res.status === 200) {
          setIsNameSearchedSuccessfully(true);
          if (!res.data.exists) {
            setIsCompanyNameAvailable(true);
          } else {
            setIsCompanyNameAvailable(false);
          }
        }
      })
      .catch((err) => console.log("err in searchCompany : ", err));
  };

  const onCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setFormData((prevState) => ({
      ...prevState,
      country: selectedCountry,
    }));
  };

  const onNationalityChange = (event) => {
    const selectedNationality = event.target.value;
    setFormData((prevState) => ({
      ...prevState,
      nationality: selectedNationality,
    }));
  };

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper elevation={3} sx={{ p: 4, pt: 1 }}>
      <Box mt={3} sx={{ width: "100%", maxWidth: "733px" }}>
        <Typography
          variant="h6"
          sx={{
            color: "1C2434",
            fontSize: "1.25rem",
            fontWeight: "bold",
          }}
          gutterBottom
        >
          Enter your company name
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
          }}
        >
          We need to check if this name is still available.
        </Typography>
        <br></br>
        <Typography
          variant="body1"
          sx={{
            marginBottom: "1.5rem",
            display: "flex",
          }}
          gutterBottom
        >
          <TextField
            // required
            label="Type company name"
            name="companyName"
            value={formData.companyName || ""}
            onChange={(e) => {
              handleChangeCompanyName(e, "companyName");
            }}
            fullWidth
            variant="outlined"
            sx={{
              maxWidth: "550px",
              width: "100%",
              mr: "2rem",
              "& .MuiOutlinedInput-root": {
                borderRadius: "0.5rem",
              },
            }}
          />
          <Typography
            variant="body1"
            sx={{
              color: "white",
              fontSize: "1rem",
              fontWeight: "medium",
              backgroundColor: "#2069F8",
              border: "1px solid #cfcfcf",
              borderRadius: "0.5rem",
              maxWidth: "130px",
              width: "100%",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleSearch}
          >
            Check
          </Typography>
        </Typography>
        {isCompanyNameAvailable && isNameSearchedSuccessfully && (
          <Typography
            mt={2}
            mb={2}
            variant="body1"
            sx={{
              color: "#2F2D3B",
              display: "flex",
              fontWeight: "700",
            }}
          >
            <GreenCheckbox
              checked
              disabled
              icon={<CheckIcon style={{ display: "none" }} />} // Hide the unchecked icon
              checkedIcon={<CheckIcon />} // White tick when checked
            />
            {formData?.companyName} {"is available"}
          </Typography>
        )}
        {isNameSearchedSuccessfully && !isCompanyNameAvailable && (
          <Typography
            mt={2}
            mb={2}
            variant="body1"
            sx={{
              color: "#2F2D3B",
              display: "flex",
              fontWeight: "700",
            }}
          >
            <RedCheckbox
              checked
              disabled
              icon={<CloseIcon style={{ display: "none" }} />} // Hide unchecked icon
              checkedIcon={<CloseIcon />} // Cross icon when checked
            />
            {formData?.companyName}
            {" is not available"}
          </Typography>
        )}
        <Typography
          variant="h6"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
          }}
          gutterBottom
        >
          Suffix
        </Typography>
        <FormControl
          variant="outlined"
          fullWidth
          required
          sx={{ maxWidth: "550px", width: "100%" }}
        >
          <Select
            name="suffix"
            value={formData.suffix || ""}
            onChange={(e) => handleChange(e, "suffix")}
            displayEmpty
            renderValue={(selected) => {
              return selected || <span style={{ color: "gray" }}>Choose</span>;
            }}
            sx={{ maxHeight: "400px", borderRadius: "0.5rem" }}
          >
            <MenuItem
              key={"LTD"}
              value={"LTD"}
              sx={{
                fontSize: "1rem",
                fontWeight: "medium",
                color: "64748B",
              }}
            >
              LTD
            </MenuItem>
            <MenuItem
              key={"LIMITED"}
              value={"LIMITED"}
              sx={{
                fontSize: "1rem",
                fontWeight: "medium",
                color: "64748B",
              }}
            >
              LIMITED
            </MenuItem>
          </Select>
        </FormControl>
        <Typography
          variant="h6"
          sx={{
            fontSize: "1rem",
            fontWeight: "medium",
            mt: 3,
          }}
          gutterBottom
        >
          Nationality
        </Typography>
        <FormControl
          variant="outlined"
          fullWidth
          required
          sx={{ maxWidth: "550px", width: "100%", mb: 1 }}
        >
          <Select
            name="nationality"
            value={formData.nationality ?? nationality[0]}
            onChange={onNationalityChange}
            sx={{ maxHeight: "400px" }}
          >
            {nationality?.length > 0 &&
              nationality.map((nationality, index) => (
                <MenuItem
                  key={index}
                  value={nationality}
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "medium",
                    color: "64748B",
                  }}
                >
                  {nationality}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      {((isNameSearchedSuccessfully && isCompanyNameAvailable) ||
        formData.isFormCompleted) && (
        <>
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1.05rem",
                fontWeight: "medium",
                marginTop: "2rem",
              }}
              gutterBottom
            >
              Registered office address
            </Typography>
            <RadioGroup
              row
              defaultValue="no"
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "0.8rem",
              }}
              value={formData.manualAddress}
              onChange={(e) => {
                setManualAddress(e.target.value);
                setPostAddress("");
                setFormData((prevState) => ({
                  ...prevState,
                  manualAddress: e.target.value,
                  addressLine1: "",
                  addressLine2: "",
                  officeAddress: "",
                  postcode: "",
                  town: "",
                  country: "",
                }));
              }}
            >
              {" "}
              <Box sx={{ width: "650px" }}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label={
                    <div className="flex">
                      <span className="mr-20">
                        Use our London Registered Company Address{" "}
                      </span>
                      <span>
                        <span className="text-[#64748B]">
                          {" "}
                          Additional £89.95 + VAT
                        </span>
                      </span>
                    </div>
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "1rem",
                      fontWeight: "medium",
                    },
                  }}
                />
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mr: 4 }}
                >
                  <a
                    href="#"
                    className="text-[#0F0049] font-medium underline"
                    onClick={handleOpen}
                  >
                    More details
                  </a>
                </Box>
              </Box>
              {!formData.useRegisteredAddress && !riseFormationAddressItem && (
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={<div>Enter your own registered address</div>}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "1rem",
                      fontWeight: "medium",
                    },
                  }}
                />
              )}
            </RadioGroup>
            {formData.manualAddress === "yes" && (
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "medium",
                    mb: 1,
                  }}
                  gutterBottom
                >
                  House name / number
                </Typography>
                <TextField
                  name="officeAddress"
                  value={formData.officeAddress}
                  onChange={(e) => handleChange(e, "officeAddress")}
                  fullWidth
                  variant="outlined"
                  sx={{ maxWidth: "555px", width: "100%", mb: "1rem", mr: 6 }}
                />{" "}
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "medium",
                    mb: 1,
                  }}
                  gutterBottom
                >
                  Postcode
                </Typography>
                <TextField
                  name="postcode"
                  value={postAddress ? postCode : formData.postcode}
                  onChange={(e) => handleChange(e, "postcode")}
                  fullWidth
                  variant="outlined"
                  sx={{ maxWidth: "555px", width: "100%", mb: "1rem", mr: 6 }}
                />
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "medium",
                    mb: 1,
                  }}
                  gutterBottom
                >
                  Address line 1
                </Typography>
                <TextField
                  name="addressLine1"
                  value={formData.addressLine1}
                  onChange={(e) => handleChange(e, "addressLine1")}
                  fullWidth
                  variant="outlined"
                  sx={{ maxWidth: "555px", width: "100%", mb: "1rem", mr: 6 }}
                />
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "medium",
                    mb: 1,
                  }}
                  gutterBottom
                >
                  Address line 2
                </Typography>
                <TextField
                  name="addressLine2"
                  value={postAddress ? municipality : formData.addressLine2}
                  onChange={(e) => handleChange(e, "addressLine2")}
                  fullWidth
                  variant="outlined"
                  sx={{ maxWidth: "555px", width: "100%", mb: "1rem", mr: 6 }}
                />
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "medium",
                    mb: 1,
                  }}
                  gutterBottom
                >
                  Town
                </Typography>
                <TextField
                  name="town"
                  value={postAddress ? subRegion : formData.town}
                  onChange={(e) => handleChange(e, "town")}
                  fullWidth
                  variant="outlined"
                  sx={{ maxWidth: "555px", width: "100%", mb: "1rem", mr: 6 }}
                />
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "medium",
                    mb: 1,
                  }}
                  gutterBottom
                >
                  Country / Region
                </Typography>
                <FormControl
                  variant="outlined"
                  fullWidth
                  required
                  sx={{ maxWidth: "550px", width: "100%", mb: 1 }}
                >
                  <Select
                    name="country"
                    value={formData.country}
                    onChange={onCountryChange}
                    sx={{ maxHeight: "400px" }}
                  >
                    {counntries?.length > 0 &&
                      counntries.map((country, index) => (
                        <MenuItem
                          key={index}
                          value={country}
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "medium",
                            color: "64748B",
                          }}
                        >
                          {country}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "medium",
                mb: 0.6,
                mt: 2,
              }}
              gutterBottom
            >
              Enter registration email address
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                mb: 2,
                color: "#787878",
              }}
              gutterBottom
            >
              This will only be used for communication with Companies House and
              not made public.
            </Typography>
            <TextField
              name="email"
              value={formData.email}
              onChange={(e) => handleChange(e, "email")}
              fullWidth
              variant="outlined"
              sx={{ maxWidth: "555px", width: "100%", mb: "1rem", mr: 6 }}
            />

            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "medium",
                mb: 0.6,
                mt: 1,
              }}
              gutterBottom
            >
              What will your company be doing?
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                mb: 2,
                color: "#787878",
              }}
              gutterBottom
            >
              Start typing in the box below to choose your relevant SIC Code.
              You can choose up to 4 SIC Codes.
            </Typography>
            <Autocomplete
              options={SICCodes}
              value={
                formData.additionalTrade ? formData.additionalTrade[0] : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select an option"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "1rem",
                      fontWeight: "normal",
                      color: "black",
                      paddingTop: "0.975rem",
                      paddingBottom: "0.5rem",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "black",
                    },
                  }}
                />
              )}
              onChange={(e, newValue) => handleChangeTrade(0, newValue)}
              sx={{ maxWidth: "555px", width: "100%" }}
            />
            {formData.additionalTrade?.slice(1)?.map((value, index) => (
              <Box key={index} ref={newTradeRef} sx={{ mt: 3, mb: 2 }}>
                <Autocomplete
                  options={SICCodes}
                  value={formData.additionalTrade[index + 1]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select an option"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "1rem",
                          fontWeight: "normal",
                          color: "black",
                          paddingTop: "0.975rem",
                          paddingBottom: "0.5rem",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "black",
                        },
                      }}
                    />
                  )}
                  onChange={(e, newValue) =>
                    handleChangeTrade(index + 1, newValue)
                  }
                  sx={{ maxWidth: "555px", width: "100%" }}
                />
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              maxWidth: "555px",
              width: "100%",
              mb: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            {formData?.additionalTrade?.length < 4 && (
              <Typography
                variant="body1"
                sx={{
                  color: "#2069F8",
                  fontWeight: "medium",
                  fontSize: "1rem",
                  mb: 1,
                  mt: 1,
                  cursor: "pointer",
                }}
                onClick={() => handleAddTrade()}
              >
                + Add Further business activities
              </Typography>
            )}
            {formData?.additionalTrade?.length > 1 && (
              <Typography
                variant="body1"
                sx={{
                  color: "#2069F8",
                  fontWeight: "medium",
                  fontSize: "1rem",
                  mb: 3,
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveTrade()}
              >
                - Remove addtional planned business activities
              </Typography>
            )}
          </Box>
        </>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 className="font-bold underline mb-4">
            Registered Office Address Service
          </h2>
          <p className="mb-2">
            The registered office address is the official address of your
            company and will appear on the public record at Companies House. In
            addition, all formal mail from Companies House and HMRC is sent to
            this address.
          </p>
          <p className="mb-2">
            If you wish to keep your residential address private, you can use
            our cost-effective registered office service based in London, EC4R.
          </p>
          <p className="mb-2">
            We will scan and email official registered mail to you.
          </p>
          <p>
            This service is renewable on a 12 monthly basis. Currently the cost
            is £89.95 +VAT per annum.
          </p>
        </Box>
      </Modal>

      <Box mt={3} display="flex">
        <Button
          sx={{
            marginTop: "1rem",
            marginLeft: "0.5rem",
            borderRadius: "1rem",
            backgroundColor: "#2069F8",
            color: "#ffffff",
            fontWeight: 700,
            padding: "0.5rem 1.5rem",
            textTransform: "none",
            width: "max-content",
            "&:hover": {
              backgroundColor: "#2069F8",
            },
          }}
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </Box>
    </Paper>
  );
};

export default CompanyDetail;
