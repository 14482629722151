import React, { useState } from "react";
import {
    Box,
    TextField,
    Button,
    Typography,
    Alert,
    CircularProgress, Link
} from "@mui/material";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import ForgotPasswordService from "../../apiServices/services/ForgotPasswordService"
const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const navigate = useNavigate();

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email); 
    };
 ;
    const handleContinue = async () => {
        if (!email) {
            setError("Email is required");
            return;
        }
        if (!validateEmail(email)) {
            setError("Enter a valid email address");
            return;
        }

        setError("");
        setLoading(true);

        try {
            await ForgotPasswordService.sendPasswordResetEmail(email);
            setSuccessMessage("Email sent successfully. Please check your inbox to continue.");
            } catch (err) {
            setError(err.message || "Failed to send email.");
        } finally {
            setLoading(false);
        }
    };
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                minHeight: "100vh",
                backgroundColor: "#F6F5F8",
                color: "#2F2D3B",
            }}
        >
            <Header />
            <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                minHeight={"85vh"}
            >
                {/* 1st box  */}
                <Box
                    maxWidth="1440px"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        padding: { md: "1rem 5rem" },
                        position: "relative",
                    }}
                >
                    
                    <Typography
                       variant="h4"
                       sx={{ mt: 4, fontWeight: "bold", textAlign: "left", mb: 2 }}
                    >
                        Please Enter Your Email To Continue.
                       
                    </Typography>
                    <Typography
                          variant="h6"
                          sx={{
                            textAlign: "center",
                            color: "#555555",
                            mb: 3,
                          }}
                    >
                         Enter your email.
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ mb: 2, maxWidth: "400px" }}>
                            {error}                        </Alert>
                    )}
                    {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}

                    <TextField
                        fullWidth
                        variant="outlined"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email address"
                        sx={{
                            mb: 2,
                            maxWidth: "600px",
                            backgroundColor: "#FFFFFF",
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                            },
                        }}
                    
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleContinue}
                        sx={{
                            maxWidth: "400px",
                            mt: 2,
                            backgroundColor: "#2069F8",
                            color: "#FFFFFF",
                            height: "50px",
                            fontSize: "16px",
                            textTransform: "none",
                            borderRadius: "8px",
                            "&:hover": {
                                backgroundColor: "#184AB6",
                            },
                        }}
                    >
                        {loading ? (
                            <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
                        ) : (
                            "Continue"
                        )}
                    </Button>
                </Box>

            </Box>
        </Box>
    );
};

export default ForgotPassword;
