import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../Header/Header";

const PaymentSuccessPage = () => {
  const [count, setCount] = useState(5);

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000);
      return () => {
        clearTimeout(timer);
      };
    } else {
      window.close();
    }
  }, [count]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        minHeight: "100vh",
        backgroundColor: "#F6F5F8",
        color: "#2F2D3B",
      }}
    >
      <Header />
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        minHeight={"65vh"}
        backgroundColor={"#F9F9F9"}
      >
        <Box
          maxWidth={"1440px"}
          width={"71%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          textAlign={"center"}
          sx={{
            padding: { md: "1rem 5rem" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                mt: 8,
              }}
            >
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                Payment Successful
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                Company Address Added
              </Typography>

              <Typography
                variant="h5"
                gutterBottom
                sx={{ mt: 2, fontWeight: "medium" }}
              >
                You will be redirected to previous page in {count} secs.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentSuccessPage;
