import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import Header from "../Header/Header";
import { retrieveChekoutSession } from "../../apiServices/services/CheckoutServices";

const Checkout = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const data = { email: email };
      const res = await retrieveChekoutSession(data);
      if (res.status === 200) {
        setLoading(false);
        if (res.data.newSessionUrl !== undefined) {
          window.location.href = res.data.newSessionUrl;
        } else {
          setError("Something went wrong, please try again.");
        }
      } else {
        throw new Error("Something went wrong, please try again.");
      }
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: "100vh",
        backgroundColor: "#F6F5F8",
        color: "#2F2D3B",
      }}
    >
      <Header />
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        minHeight={"85vh"}
      >
        <Box
          maxWidth="1440px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            padding: { md: "1rem 5rem" },
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            sx={{ mt: 4, fontWeight: "bold", textAlign: "center", mb: 2 }}
          >
            Resume Company Incorporation checkout
          </Typography>

          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              color: "#555555",
              mb: 3,
            }}
          >
            Please enter your email to complete your payment and secure your Company Name.
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2, maxWidth: "400px" }}>
              {"Unable to process the session"}
            </Alert>
          )}

          <TextField
            fullWidth
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
            sx={{
              mb: 2,
              maxWidth: "600px",
              backgroundColor: "#FFFFFF",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
          />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleClick}
            sx={{
              maxWidth: "400px",
              mt: 2,
              backgroundColor: "#2069F8",
              color: "#FFFFFF",
              height: "50px",
              fontSize: "16px",
              textTransform: "none",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#184AB6",
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />
            ) : (
              "Continue"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Checkout;
